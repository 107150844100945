import React from 'react'
import { Menu, Dropdown, Tooltip, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import JobLink from './JobLink'
import DrawerLink from '../shared/DrawerLink'
import JobEditDrawer from './JobEditDrawer'
import { hasPermission } from '../../utilities/permissions'
import { useMutation } from '@apollo/react-hooks'
import { JOB_DOWNLOAD_PHOTO_LIST } from '../../graphql/mutations'
import { useErrors, useGraphqlError } from '../../utilities/hooks'
import { saveAs } from 'file-saver'

const JobActionsDropdown = ({ job }) => {
  const canManageEntities = hasPermission('admin_manage_entities')

  const [jobDownloadPhotoList, { data, loading, error }] = useMutation(JOB_DOWNLOAD_PHOTO_LIST)
  useErrors(data?.jobDownloadPhotoList?.errors)
  useGraphqlError(error)

  const onDownloadPhotoList = async () => {
    const params = { variables: { input: { id: job.id } } }
    const response = await jobDownloadPhotoList(params)
    if (response?.data?.jobDownloadPhotoList?.errors?.length === 0) {
      message.success('Downloading.')
      const blob = response?.data?.jobDownloadPhotoList?.file?.blob
      const filename = response?.data?.jobDownloadPhotoList?.file?.filename
      const fileContents = window.atob(blob)
      const newBlob = new Blob([fileContents], { type: 'text/plain;charset=utf-8' })
      saveAs(newBlob, filename)
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <JobLink job={job}>View</JobLink>
      </Menu.Item>
      <Menu.Item key="1">
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          <DrawerLink drawerElement={JobEditDrawer} childProps={{ job: job }} clickable={canManageEntities} width={500}>
            {canManageEntities && <span>Edit Job</span>}
            {!canManageEntities && (
              <Tooltip title="You must request permission to be able to manage entities.">
                <span style={{ color: 'gray' }}>Edit Job</span>
              </Tooltip>
            )}
          </DrawerLink>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="2">
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          {canManageEntities && <span onClick={loading ? () => {} : onDownloadPhotoList}>{loading ? 'Downloading...' : 'Download Photo List'}</span>}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Download Photo List</span>
            </Tooltip>
          )}
        </Tooltip>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default JobActionsDropdown
