import React, { useState, useCallback } from 'react'
import { Comment, Avatar, Form, Button, List, Input, message } from 'antd'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { get } from '../../../utilities/storage'
import moment from 'moment'
import gravatar from 'gravatar'
import { hasPermission } from '../../../utilities/permissions'
import { SUPPORT_REQUEST_EVENT_LIST_QUERY } from '../../../graphql/queries'
import { CREATE_SUPPORT_REQUEST_EVENT } from '../../../graphql/mutations'

const { TextArea } = Input

const CommentList = ({ comments }) => {
  const data = comments.map((comment) => {
    const avatarUrl = gravatar.url(comment.user.email, { s: '100', d: 'mp' })

    return {
      author: comment.user.fullName,
      avatar: avatarUrl,
      content: comment.comment,
      datetime: moment(comment.createdAt).fromNow()
    }
  })
  return <List dataSource={data} itemLayout="horizontal" renderItem={(props) => <Comment {...props} />} />
}

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </>
)

const RequestComments = ({ request }) => {
  const [value, setValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const hasAccess = hasPermission('admin_support_requests')
  const { data: eventsData, refetch } = useQuery(SUPPORT_REQUEST_EVENT_LIST_QUERY, {
    variables: { eventType: 'comment', supportRequestId: request.id, perPage: 9999 }
  })
  const comments = eventsData?.supportRequestEvents?.items
  const [createSupportRequestEvent] = useMutation(CREATE_SUPPORT_REQUEST_EVENT)
  const currentUser = get('user')
  const avatarUrl = gravatar.url(currentUser.email, { s: '100', d: 'mp' })

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)
    const input = { supportRequestId: request.id, comment: value, userId: currentUser.id }
    const params = { variables: { input: input } }
    const response = await createSupportRequestEvent(params)
    const errors = response.data?.createSupportRequestEvent?.errors
    setSubmitting(false)
    setValue('')

    if (errors && errors.length > 0) return
    refetch()
    message.success('New comment was added.')
  }, [request, createSupportRequestEvent, value, refetch, currentUser])

  return (
    <>
      {comments && comments.length > 0 && <CommentList comments={comments} />}
      {hasAccess && (
        <Comment
          avatar={<Avatar src={avatarUrl} />}
          content={<Editor onChange={(e) => setValue(e.target.value)} onSubmit={handleSubmit} submitting={submitting} value={value} />}
        />
      )}
    </>
  )
}

export default RequestComments
