import React, { useCallback } from 'react'
import { Menu, Dropdown, Tooltip, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { hasPermission } from '../../utilities/permissions'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_SUPPORT_REQUEST } from '../../graphql/mutations'
import PubSub from 'pubsub-js'

const RequestBulkActionsDropdown = ({ selectedRowKeys, clearSelection }) => {
  const hasAccess = hasPermission('admin_support_requests')
  const [updateSupportRequest] = useMutation(UPDATE_SUPPORT_REQUEST)

  const approveRequest = useCallback(async () => {
    for (const id of selectedRowKeys) {
      const params = {
        variables: {
          input: {
            id,
            status: 'approved',
            comment: null,
            shippingRateId: null
          }
        }
      }
      const response = await updateSupportRequest(params)
      const errors = response.data?.updateSupportRequest?.errors
      if (errors && errors.length > 0) return
    }

    message.success(`${selectedRowKeys.length} support requests updated.`)
    PubSub.publish('PAGED_LIST_REFRESH')
    clearSelection()
  }, [selectedRowKeys, clearSelection, updateSupportRequest])

  const approveManualRequest = useCallback(async () => {
    for (const id of selectedRowKeys) {
      const params = {
        variables: {
          input: {
            id,
            status: 'approved_manual',
            comment: null,
            shippingRateId: null
          }
        }
      }
      const response = await updateSupportRequest(params)
      const errors = response.data?.updateSupportRequest?.errors
      if (errors && errors.length > 0) return
    }

    message.success(`${selectedRowKeys.length} support requests updated.`)
    PubSub.publish('PAGED_LIST_REFRESH')
    clearSelection()
  }, [selectedRowKeys, clearSelection, updateSupportRequest])

  const completeRequest = useCallback(async () => {
    for (const id of selectedRowKeys) {
      const params = {
        variables: {
          input: {
            id,
            status: 'complete',
            comment: 'Manual Complete',
            shippingRateId: null
          }
        }
      }
      const response = await updateSupportRequest(params)
      const errors = response.data?.updateSupportRequest?.errors
      if (errors && errors.length > 0) return
    }

    message.success(`${selectedRowKeys.length} support requests updated.`)
    PubSub.publish('PAGED_LIST_REFRESH')
    clearSelection()
  }, [selectedRowKeys, clearSelection, updateSupportRequest])

  const menu = (
    <Menu>
      <Menu.Item key="1">
        {hasAccess && (
          <div className="link" onClick={approveRequest} style={{ cursor: 'pointer' }}>
            Approve
          </div>
        )}
        {!hasAccess && (
          <Tooltip title="You must request permission to be able to manage support entities.">
            <span style={{ color: 'gray' }}>Approve</span>
          </Tooltip>
        )}
      </Menu.Item>
      <Menu.Item key="2">
        {hasAccess && (
          <div className="link" onClick={approveManualRequest} style={{ cursor: 'pointer' }}>
            Approve - Manual
          </div>
        )}
        {!hasAccess && (
          <Tooltip title="You must request permission to be able to manage support entities.">
            <span style={{ color: 'gray' }}>Approve - Manual</span>
          </Tooltip>
        )}
      </Menu.Item>
      <Menu.Item key="3">
        {hasAccess && (
          <div className="link" onClick={completeRequest} style={{ cursor: 'pointer' }}>
            Complete
          </div>
        )}
        {!hasAccess && (
          <Tooltip title="You must request permission to be able to manage support entities.">
            <span style={{ color: 'gray' }}>Complete</span>
          </Tooltip>
        )}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {selectedRowKeys && selectedRowKeys.length > 1 && (
        <Dropdown overlay={menu} trigger={['click']}>
          <span className="ant-dropdown-link" style={{ cursor: 'pointer', marginLeft: '14px' }}>
            Bulk Actions &nbsp;
            <DownOutlined />
          </span>
        </Dropdown>
      )}
    </>
  )
}

export default RequestBulkActionsDropdown
