import React from 'react'
import { dateFormatter, idFormatter, humanFormatter, jobListFormatter } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import Copy from '../shared/Copy'
import RequestActionsDropdown from './RequestActionsDropdown'
import RequestLink from './RequestLink'
import { SUPPORT_REQUEST_LIST_QUERY, STUDIO_SLIM_LIST_QUERY } from '../../graphql/queries'
import ExportButton from './ExportButton'

const promotionName = (studioPromotion) => {
  const promotionName = studioPromotion?.promotion?.name ?? 'N/A'
  const offerCode = studioPromotion?.offer?.code
  if (promotionName?.toUpperCase()?.trim() === 'FLASH SALE' && offerCode) {
    return `${promotionName} - ${offerCode}`
  }
  return promotionName
}

const REQUEST_STATUSES = [
  { label: 'Requested', value: 'requested' },
  { label: 'Approved', value: 'approved' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Denied', value: 'denied' },
  { label: 'Complete', value: 'complete' }
]

const smsCampaignsListProps = listProps('supportRequests', SUPPORT_REQUEST_LIST_QUERY, {
  search: false,
  controls: [
    {
      dataField: 'requestType',
      placeholder: 'requestType',
      defaultValue: 'custom_promotion'
    },
    {
      controlType: 'static-select',
      dataField: 'status',
      placeholder: 'Status',
      width: 170,
      options: REQUEST_STATUSES,
      defaultValue: 'requested'
    },
    {
      controlType: 'select',
      dataField: 'studio',
      placeholder: 'Studio',
      width: 250,
      optionsQuery: {
        query: STUDIO_SLIM_LIST_QUERY,
        dataKey: 'studios',
        value: 'id',
        label: 'name',
        params: { withSupportRequests: true }
      }
    },
    {
      controlType: 'range-picker',
      dateFrom: 'dateFrom',
      dateTo: 'dateTo'
    },
    {
      controlType: 'custom',
      component: ExportButton
    }
  ],
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 120,
      render: (id, row) => (
        <RequestLink request={row}>
          <Copy text={id}>{idFormatter(id)}</Copy>
        </RequestLink>
      )
    },
    {
      dataIndex: 'studio.name',
      title: 'Studio',
      render: (_, row) => <div>{row.studio.name}</div>
    },
    {
      dataIndex: 'studioPromotion.promotion.name',
      title: 'Promotion',
      render: (_, row) => <div>{promotionName(row.studioPromotion)}</div>
    },
    {
      dataIndex: 'studioPromotion.startAt',
      title: 'First Day',
      render: (_, row) => row.studioPromotion.startAt && <div>{dateFormatter(row.studioPromotion.startAt, true, true)}</div>
    },
    {
      dataIndex: 'studioPromotion.endAt',
      title: 'Last Day',
      render: (_, row) => row.studioPromotion.endAt && <div>{dateFormatter(row.studioPromotion.endAt, true, true)}</div>
    },
    {
      dataIndex: 'studioPromotion.studioPromotionJobs',
      title: 'Job List',
      render: (id, row) =>
        row.studioPromotion &&
        !!row.studioPromotion.studioPromotionJobs.length && (
          <RequestLink request={row}>
            <Copy text={id}>{jobListFormatter(row.studioPromotion.studioPromotionJobs)}</Copy>
          </RequestLink>
        )
    },
    {
      dataIndex: 'status',
      title: 'Status',
      render: (status) => <div>{humanFormatter(status)}</div>
    },
    {
      dataIndex: 'createdAt',
      title: 'Requested',
      render: (_, row) => (
        <div>
          {dateFormatter(row.createdAt)} ({row.createdAgo} ago)
        </div>
      )
    },
    {
      dataIndex: 'completedAt',
      title: 'Completed',
      render: (_, row) =>
        row.completedAt && (
          <div>
            {dateFormatter(row.completedAt)} (in {row.completedIn})
          </div>
        )
    },
    {
      render: (_, row) => !row.completedAt && <RequestActionsDropdown request={row} />
    }
  ]
})

export default smsCampaignsListProps
