import React, { useCallback } from 'react'
import { Menu, Dropdown, Tooltip, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { hasPermission } from '../../utilities/permissions'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_SUPPORT_REQUEST } from '../../graphql/mutations'
import DrawerLink from '../shared/DrawerLink'
import DenyDrawer from './DenyDrawer'
import ApproveDrawer from './ApproveDrawer'
import PubSub from 'pubsub-js'

const RequestActionsDropdown = ({ request }) => {
  const hasAccess = hasPermission('admin_support_requests')
  const [updateSupportRequest] = useMutation(UPDATE_SUPPORT_REQUEST)

  const approveRequest = useCallback(async () => {
    const params = {
      variables: {
        input: {
          id: request.id,
          status: 'approved',
          comment: null,
          shippingRateId: request.orderReprint?.shippingRateId ? request.orderReprint?.shippingRateId : null
        }
      }
    }
    const response = await updateSupportRequest(params)
    const errors = response.data?.updateSupportRequest?.errors
    if (errors && errors.length > 0) return

    message.success('Support Request updated.')
    PubSub.publish('PAGED_LIST_REFRESH')
  }, [request, updateSupportRequest])

  const approveManualRequest = useCallback(async () => {
    const params = {
      variables: {
        input: {
          id: request.id,
          status: 'approved_manual',
          comment: null,
          shippingRateId: request.orderReprint?.shippingRateId ? request.orderReprint?.shippingRateId : null
        }
      }
    }
    const response = await updateSupportRequest(params)
    const errors = response.data?.updateSupportRequest?.errors
    if (errors && errors.length > 0) return

    message.success('Support Request updated.')
    PubSub.publish('PAGED_LIST_REFRESH')
  }, [request, updateSupportRequest])

  const completeRequest = useCallback(async () => {
    const params = {
      variables: {
        input: {
          id: request.id,
          status: 'complete',
          comment: 'Manual Complete',
          shippingRateId: request.orderReprint?.shippingRateId ? request.orderReprint?.shippingRateId : null
        }
      }
    }
    const response = await updateSupportRequest(params)
    const errors = response.data?.updateSupportRequest?.errors
    if (errors && errors.length > 0) return

    message.success('Support Request updated.')
    PubSub.publish('PAGED_LIST_REFRESH')
  }, [request, updateSupportRequest])

  const menu = (
    <Menu>
      {request.status !== 'approved' && request.status !== 'complete' && (
        <Menu.Item key="1">
          {hasAccess && request.requestType !== 'reprint' && (
            <div className="link" onClick={approveRequest} style={{ cursor: 'pointer' }}>
              Approve
            </div>
          )}
          {hasAccess && request.requestType === 'reprint' && (
            <DrawerLink drawerElement={ApproveDrawer} childProps={{ request: request }} clickable={hasAccess} width={500}>
              {hasAccess && <span>Approve</span>}
            </DrawerLink>
          )}
          {!hasAccess && (
            <Tooltip title="You must request permission to be able to manage support entities.">
              <span style={{ color: 'gray' }}>Approve</span>
            </Tooltip>
          )}
        </Menu.Item>
      )}
      {request.status !== 'approved' && request.status !== 'approved_manual' && request.status !== 'complete' && request.requestType === 'reprint' && (
        <Menu.Item key="2">
          {hasAccess && (
            <div className="link" onClick={approveManualRequest} style={{ cursor: 'pointer' }}>
              Approve - Manual
            </div>
          )}
          {!hasAccess && (
            <Tooltip title="You must request permission to be able to manage support entities.">
              <span style={{ color: 'gray' }}>Approve - Manual</span>
            </Tooltip>
          )}
        </Menu.Item>
      )}
      {request.status !== 'complete' && request.status !== 'denied' && request.requestType === 'reprint' && (
        <Menu.Item key="3">
          {hasAccess && (
            <div className="link" onClick={completeRequest} style={{ cursor: 'pointer' }}>
              Complete
            </div>
          )}
          {!hasAccess && (
            <Tooltip title="You must request permission to be able to manage support entities.">
              <span style={{ color: 'gray' }}>Complete</span>
            </Tooltip>
          )}
        </Menu.Item>
      )}
      {request.status !== 'denied' && request.status !== 'complete' && (
        <Menu.Item key="4">
          <DrawerLink drawerElement={DenyDrawer} childProps={{ request: request }} clickable={hasAccess} width={500}>
            {hasAccess && <span>Deny</span>}
            {!hasAccess && (
              <Tooltip title="You must request permission to be able to manage entities.">
                <span style={{ color: 'gray' }}>Deny</span>
              </Tooltip>
            )}
          </DrawerLink>
        </Menu.Item>
      )}
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default RequestActionsDropdown
