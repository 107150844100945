import React from 'react'
import { Menu, Dropdown, Tooltip, Popconfirm, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useModal } from 'sunflower-antd'
import DrawerLink from '../shared/DrawerLink'
import SendToLabModal from './actions/SendToLabModal'
import RefundCreateDrawer from '../refunds/RefundCreateDrawer'
import { DownOutlined } from '@ant-design/icons'
import { hasPermission } from '../../utilities/permissions'
import OrderEditDrawer from './OrderEditDrawer'
import OrderShipDrawer from './OrderShipDrawer'
import { useMutation } from '@apollo/react-hooks'
import { ORDER_RESEND_DD_RELEASE, ORDER_RESEND_RECEIPT, CANCEL_ORDER, ORDER_DOWNLOAD_FINAL_IMAGES_LIST } from '../../graphql/mutations'
import { useErrors, useGraphqlError } from '../../utilities/hooks'
import { saveAs } from 'file-saver'

const ORDER_CANCELED = 'canceled'

const OrderActionsDropdown = ({ order }) => {
  const navigate = useNavigate()
  const { modalProps: _sendLabProps, show: showSend, close: closeSend } = useModal({ defaultVisible: false })
  const [resendReceipt, { data }] = useMutation(ORDER_RESEND_RECEIPT, {
    onError: (error) => {
      console.log(error)
    }
  })
  const [resendDdRelease, { data: dataRelease }] = useMutation(ORDER_RESEND_DD_RELEASE, {
    onError: (error) => {
      console.log(error)
    }
  })
  const [cancelOrder, { data: dataCancelOrder }] = useMutation(CANCEL_ORDER, {
    onError: (error) => {
      message.error(`Error while cancelling bulk order: ${JSON.stringify(error)}`)
      console.log(error)
    }
  })
  const sendLabProps = { ..._sendLabProps, ...{ show: showSend, close: closeSend } }
  const [orderDownloadFinalImagesList, { data: dataDownload, loading, error }] = useMutation(ORDER_DOWNLOAD_FINAL_IMAGES_LIST)
  useGraphqlError(error)
  useErrors(dataDownload?.orderDownloadFinalImagesList?.errors)
  useErrors(data?.orderResendReceipt?.errors)
  useErrors(dataRelease?.orderResendDdRelease?.errors)
  useErrors(dataCancelOrder?.cancelOrder?.errors)

  const viewOrder = () => {
    navigate(`/orders/${order.num}`)
  }

  const onResendReceipt = async () => {
    const response = await resendReceipt({ variables: { input: { id: order.id } } })
    if (response?.data?.orderResendReceipt?.errors?.length === 0) {
      message.success(`Receipt sent to ${order.email}.`)
    }
  }

  const onResendDdRelease = async () => {
    const response = await resendDdRelease({ variables: { input: { id: order.id } } })
    if (response?.data?.orderResendDdRelease?.errors?.length === 0) {
      message.success(`Receipt sent to ${order.email}.`)
    }
  }

  const onCancelBulkOrder = async () => {
    const response = await cancelOrder({ variables: { input: { id: order.id } } })
    if (response?.data?.cancelOrder?.errors?.length === 0) {
      message.success('Bulk order canceled.')
    }
  }

  const onDownloadPhotoList = async () => {
    const params = { variables: { input: { id: order.id } } }
    const response = await orderDownloadFinalImagesList(params)
    if (response?.data?.orderDownloadFinalImagesList?.errors?.length === 0) {
      message.success('Downloading.')
      const blob = response?.data?.orderDownloadFinalImagesList?.file?.blob
      const filename = response?.data?.orderDownloadFinalImagesList?.file?.filename
      const fileContents = window.atob(blob)
      const newBlob = new Blob([fileContents], { type: 'text/plain;charset=utf-8' })
      saveAs(newBlob, filename)
    }
  }

  const canResendOrders = hasPermission('admin_resend_orders')
  const canResendReceipts = hasPermission('admin_resend_receipts')
  const canManageRefunds = hasPermission('admin_manage_refunds')
  const canManageEntities = hasPermission('admin_manage_entities')
  const nonProductionEnv = process.env.REACT_APP_DEPLOYMENT !== 'admin'
  const orderPaid = order.status === 'paid'
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={viewOrder}>
        <span>View</span>
      </Menu.Item>
      <Menu.Item key="1">
        <DrawerLink drawerElement={OrderEditDrawer} childProps={{ order: order }} clickable={canManageEntities} width={500}>
          {canManageEntities && <span>Edit Order</span>}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Edit Order</span>
            </Tooltip>
          )}
        </DrawerLink>
      </Menu.Item>
      <Menu.Item key="2">
        {canResendOrders && <span onClick={showSend}>Send to Lab</span>}
        {!canResendOrders && (
          <Tooltip title="You must request permission to be able to resend orders.">
            <span style={{ color: 'gray' }}>Send to Lab</span>
          </Tooltip>
        )}
        <SendToLabModal {...sendLabProps} order={order} />
      </Menu.Item>
      <Menu.Item key="3">
        {canResendReceipts && (
          <Popconfirm title={`Send this receipt to ${order.email}?`} onConfirm={onResendReceipt}>
            <span>Resend Receipt</span>
          </Popconfirm>
        )}
        {!canResendReceipts && (
          <Tooltip title="You must request permission to be able to resend receipts.">
            <span style={{ color: 'gray' }}>Resend Receipt</span>
          </Tooltip>
        )}
      </Menu.Item>
      <Menu.Item key="4">
        {canResendReceipts && (
          <Popconfirm title={`Send this DD release to ${order.email}?`} onConfirm={onResendDdRelease}>
            <span>Resend DD Release</span>
          </Popconfirm>
        )}
        {!canResendReceipts && (
          <Tooltip title="You must request permission to be able to resend receipts.">
            <span style={{ color: 'gray' }}>Resend DD Release</span>
          </Tooltip>
        )}
      </Menu.Item>
      <Menu.Item key="5" disabled={!order.refundable}>
        <DrawerLink drawerElement={RefundCreateDrawer} childProps={{ order: order, payoutId: order.payoutId }} clickable={canManageRefunds} width={500}>
          {canManageRefunds && <span>Create Refund</span>}
          {!canManageRefunds && (
            <Tooltip title="You must request permission to be able to manage refunds.">
              <span style={{ color: 'gray' }}>Create Refund</span>
            </Tooltip>
          )}
        </DrawerLink>
      </Menu.Item>
      {order.bulkShipOrderId && order.status !== ORDER_CANCELED && (
        <Menu.Item key="6">
          {canManageEntities && (
            <Popconfirm title={`Are you sure you want to cancel the bulk order ${order.num}?`} onConfirm={onCancelBulkOrder}>
              <span>Cancel Bulk Order</span>
            </Popconfirm>
          )}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Cancel Bulk Order</span>
            </Tooltip>
          )}
        </Menu.Item>
      )}
      {nonProductionEnv && (
        <Menu.Item key="7">
          <DrawerLink drawerElement={OrderShipDrawer} childProps={{ order: order }} clickable={orderPaid} width={500}>
            {orderPaid && <span>Ship</span>}
            {!orderPaid && (
              <Tooltip title="Order was shipped already.">
                <span style={{ color: 'gray' }}>Ship</span>
              </Tooltip>
            )}
          </DrawerLink>
        </Menu.Item>
      )}
      <Menu.Item key="8">
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          {canManageEntities && <span onClick={loading ? () => {} : onDownloadPhotoList}>{loading ? 'Downloading...' : 'Download Photo List'}</span>}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Download Photo List</span>
            </Tooltip>
          )}
        </Tooltip>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default OrderActionsDropdown
