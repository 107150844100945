import React, { useEffect } from 'react'
import { Descriptions } from 'antd'
import Prismjs from 'prismjs'
import Copy from '../../shared/Copy'
import { OrderLink, StudioLink, UserLink, JobLink } from '../../shared/Links'
import { formatTimestamp, humanFormatter, dateFormatter } from '../../../utilities/formatters'
import RequestActionsDropdown from '../RequestActionsDropdown'

const { Item } = Descriptions

const Request = ({ request }) => {
  useEffect(() => {
    Prismjs.highlightAll()
  })

  const order = request.orderReprint?.order
  const isReprint = request.requestType === 'reprint'
  const title = isReprint ? 'Reprint Request' : 'SMS Campaign'

  return (
    <>
      <Descriptions title={`${title} Details`} size="small" column={1} bordered extra={<RequestActionsDropdown request={request} />}>
        <Item label="ID">
          <Copy text={request.id}>{request.id}</Copy>
        </Item>
        <Item label="Request Type">{humanFormatter(request.requestType)}</Item>
        {isReprint && <Item label="Lab">{order.lab.name}</Item>}
        <Item label="Studio">
          <StudioLink studio={request.studio} />
        </Item>
        {request.studio.owner && (
          <Item label="Studio Owner">
            <UserLink user={request.studio.owner} />
          </Item>
        )}
        {isReprint && (
          <Item label="Order">
            <OrderLink order={order} />
          </Item>
        )}
        {isReprint && <Item label="Reprint Number">{request.orderReprint?.num}</Item>}
        {isReprint && request.orderReprint && <Item label="Reprint Reason">{humanFormatter(request.orderReprint.reason)}</Item>}
        {isReprint && request.orderReprint?.extraInfo && <Item label="Reprint Details">{request.orderReprint?.extraInfo}</Item>}
        <Item label="Status">{humanFormatter(request.status)}</Item>
        {isReprint && (
          <Item label="Shipping">
            {request.orderReprint?.shippingRate?.description} ({request.orderReprint?.shippingRate?.rate})
          </Item>
        )}
        {!isReprint && (
          <>
            <Item label="Promotion">{request.studioPromotion.promotion.name}</Item>
            {!!request.studioPromotion.studioPromotionJobs.length && (
              <Item label="Job List">
                {request.studioPromotion.studioPromotionJobs.map((spj, i, arr) => (
                  <>
                    <JobLink job={spj.job} />
                    {arr.length - 1 !== i && <span>, </span>}
                  </>
                ))}
              </Item>
            )}
            {request.studioPromotion.startAt && <Item label="First Day">{dateFormatter(request.studioPromotion.startAt, true, true)}</Item>}
            {request.studioPromotion.endAt && <Item label="Last Day">{dateFormatter(request.studioPromotion.endAt, true, true)}</Item>}
          </>
        )}
        <Item label="Requested">
          {formatTimestamp(request.createdAt)} ({request.createdAgo} ago)
        </Item>
        {isReprint && <Item label="Assignee">{request.user?.fullName}</Item>}
        {request.status === 'complete' && (
          <Item label="Completed">
            {request.completedAt} (in {request.completedIn})
          </Item>
        )}
        {isReprint && request.status === 'complete' && <Item label="Tracking Number">{request.orderReprint?.trackingNumber}</Item>}
        {isReprint && request.status === 'complete' && <Item label="Shipping Carrier">{request.orderReprint?.carrier}</Item>}
        {isReprint && request.orderReprint.order.lab.key === 'whcc' && (
          <Item label="Color Correction">{request.orderReprint?.colorCorrection ? 'Yes' : 'No'}</Item>
        )}
        {isReprint && request.orderReprint.order.lab.key === 'whcc' && (
          <Item label="Color Correction">{request.orderReprint?.colorCorrection ? 'Yes' : 'No'}</Item>
        )}
      </Descriptions>
    </>
  )
}

export default Request
