import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import { Button, Menu, Dropdown, Tooltip, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import DrawerLink from '../shared/DrawerLink'
import UserEditDrawer from './UserEditDrawer'
import UserLink from './UserLink'
import { hasPermission } from '../../utilities/permissions'
import { RESET_PASSWORD_LINK } from '../../graphql/mutations'

const UserActionsDropdown = ({ user }) => {
  const [resetPasswordLink, { data, loading, error }] = useMutation(RESET_PASSWORD_LINK)
  useErrors(data?.resetPasswordLink?.errors)
  useGraphqlError(error)

  const generateResetPasswordLink = async () => {
    const params = { variables: { input: { id: user.id } } }
    const response = await resetPasswordLink(params)
    const errors = response.data?.resetPasswordLink?.errors
    if (errors?.length > 0) {
      message.error(`An error occurred while generating reset password link for user with ID ${user.id}: ${errors.map((e) => e.message).join('\n')}`)
      console.log(errors)
    } else {
      message.success('Reset password link successfully generated and copied to the clipboard.')
      navigator.clipboard.writeText(response.data?.resetPasswordLink?.resetPasswordUrl)
    }
  }

  const canManageEntities = hasPermission('admin_manage_entities')
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <UserLink user={user}>View</UserLink>
      </Menu.Item>
      <Menu.Item key="1">
        <DrawerLink drawerElement={UserEditDrawer} childProps={{ user: user }} clickable={canManageEntities} width={500}>
          {canManageEntities && <span>Edit User</span>}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Edit User</span>
            </Tooltip>
          )}
        </DrawerLink>
      </Menu.Item>
      <Menu.Item key="2">
        <Button type="link" style={{ padding: 0 }} disabled={loading} onClick={generateResetPasswordLink}>
          {canManageEntities && <span>Password Reset Link</span>}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Password Reset Link</span>
            </Tooltip>
          )}
        </Button>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default UserActionsDropdown
