import React from 'react'
import { dateFormatter, idFormatter, humanFormatter, studioHref } from '../../utilities/formatters'
import listProps from '../../utilities/listProps'
import Copy from '../shared/Copy'
import RequestActionsDropdown from './RequestActionsDropdown'
import RequestBulkActionsDropdown from './RequestBulkActionsDropdown'
import RequestLink from './RequestLink'
import { SUPPORT_REQUEST_LIST_QUERY, LABS_LIST_QUERY, STUDIO_SLIM_LIST_QUERY } from '../../graphql/queries'
import ExportButton from './ExportButton'

const REQUEST_STATUSES = [
  { label: 'Requested', value: 'requested' },
  { label: 'Approved', value: 'approved' },
  { label: 'Approved - Manual', value: 'approved_manual' },
  { label: 'Denied', value: 'denied' },
  { label: 'Failed', value: 'failed' },
  { label: 'Complete', value: 'complete' }
]

const requestListProps = listProps('supportRequests', SUPPORT_REQUEST_LIST_QUERY, {
  searchPlaceholder: 'Search reprints...',
  controls: [
    {
      dataField: 'requestType',
      placeholder: 'requestType',
      defaultValue: 'reprint'
    },
    {
      controlType: 'static-select',
      dataField: 'status',
      placeholder: 'Status',
      width: 170,
      options: REQUEST_STATUSES,
      defaultValue: 'requested'
    },
    {
      controlType: 'select',
      dataField: 'lab',
      placeholder: 'Lab',
      width: 170,
      optionsQuery: { query: LABS_LIST_QUERY, dataKey: 'labs', value: 'id', label: 'name' }
    },
    {
      controlType: 'select',
      dataField: 'studio',
      placeholder: 'Studio',
      width: 250,
      optionsQuery: {
        query: STUDIO_SLIM_LIST_QUERY,
        dataKey: 'studios',
        value: 'id',
        label: 'name',
        params: { withSupportRequests: true }
      }
    },
    {
      controlType: 'range-picker',
      dateFrom: 'dateFrom',
      dateTo: 'dateTo'
    },
    {
      controlType: 'custom',
      component: ExportButton
    },
    {
      controlType: 'custom',
      component: RequestBulkActionsDropdown
    }
  ],
  columns: [
    {
      dataIndex: 'id',
      title: 'ID',
      width: 120,
      render: (id, row) => (
        <RequestLink request={row}>
          <Copy text={id}>{idFormatter(id)}</Copy>
        </RequestLink>
      )
    },
    {
      dataIndex: 'orderReprint.order.lab.name',
      title: 'Lab',
      sorter: true,
      render: (_, row) => <div>{row.orderReprint.order.lab.name}</div>
    },
    {
      dataIndex: 'studio.name',
      title: 'Studio',
      sorter: true,
      render: (_, row) => <div>{row.studio.name}</div>
    },
    {
      dataIndex: 'orderReprint.order.num',
      title: 'Order',
      sorter: true,
      render: (_, row) => (
        <div>
          <a href={studioHref(`/storefront/orders/${row.orderReprint.order.id}`)} target="_blank" rel="noopener noreferrer">
            {row.orderReprint.order.num}
          </a>
        </div>
      )
    },
    {
      dataIndex: 'orderReprint.order.trackingUrl',
      title: 'Tracking URL',
      sorter: true,
      render: (_, row) => (
        <div>
          {row.orderReprint.order.trackingUrl ? (
            <a href={row.orderReprint.order.trackingUrl} target="_blank" rel="noopener noreferrer">
              Tracking URL
            </a>
          ) : (
            'N/A'
          )}
        </div>
      )
    },
    {
      dataIndex: 'requestDetails',
      title: 'Request Details',
      sorter: true,
      render: (_, row) =>
        row.orderReprint && (
          <div>
            Reason: {row.orderReprint.reason}
            {row.orderReprint.extraInfo ? ` (${row.orderReprint.extraInfo})` : ''}
          </div>
        )
    },
    {
      dataIndex: 'status',
      title: 'Status',
      render: (status) => <div>{humanFormatter(status)}</div>
    },
    {
      dataIndex: 'createdAt',
      title: 'Requested',
      render: (_, row) => (
        <div>
          {dateFormatter(row.createdAt)} ({row.createdAgo} ago)
        </div>
      )
    },
    {
      dataIndex: 'completedAt',
      title: 'Completed',
      render: (_, row) =>
        row.completedAt && (
          <div>
            {dateFormatter(row.completedAt)} (in {row.completedIn})
          </div>
        )
    },
    {
      width: 90,
      render: (_, row) => !row.completedAt && <RequestActionsDropdown request={row} />
    }
  ]
})

export default requestListProps
