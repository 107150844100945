import React from 'react'
import { HomeOutlined, CustomerServiceOutlined } from '@ant-design/icons'
import BaseLayout from '../layout/BaseLayout'
import Page from '../layout/Page'
import PagedList from '../shared/PagedList'
import smsCampaignsListProps from '../requests/smsCampaignsListProps'
import { buildBreadCrumbs } from '../../utilities/breadcrumbs'

const Requests = () => {
  return (
    <BaseLayout section="sms-campaigns" breadcrumb={breadcrumb}>
      <Page>
        <PagedList {...smsCampaignsListProps} />
      </Page>
    </BaseLayout>
  )
}

const breadcrumb = buildBreadCrumbs([
  {
    href: '/',
    icon: <HomeOutlined />
  },
  {
    icon: <CustomerServiceOutlined />,
    name: 'Support'
  },
  {
    href: '/sms-campaigns',
    name: 'SMS Campaigns'
  }
])

export default Requests
