import React, { useCallback } from 'react'

// Plugins
import { Dropdown, Menu, message, Tooltip, Popconfirm } from 'antd'

// GraphQL
import { DELETE_ORDER } from '../../graphql/mutations'
import { CUSTOMER_SMS_CODE_QUERY } from '../../graphql/queries'

// Components
import CustomerLink from './CustomerLink'
import DrawerLink from '../shared/DrawerLink'
import { DownOutlined } from '@ant-design/icons'
import CustomerEditDrawer from './CustomerEditDrawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useErrors, useGraphqlError } from '../../utilities/hooks'
import useImperativeQuery from '../../utilities/useImperativeQuery'

const CustomerActionsDropdown = ({ customer }) => {
  const customerCartId = customer.cartId
  const canManageEntities = hasPermission('admin_manage_entities')

  const smsQuery = useImperativeQuery(CUSTOMER_SMS_CODE_QUERY, { variables: { id: customer.id } })

  const getSmsCode = useCallback(async () => {
    const result = await smsQuery()
    const code = result.data.customer.smsCode
    if (code) {
      message.success(`SMS code for ${customer.phone} is ${result.data.customer.smsCode}`, 15)
    } else {
      message.warn('No recent code found. Have the customer try to login again')
    }
  }, [customer, smsQuery])

  const [deleteOrder, { data: dataDeleteOrder, error: errorDeleteOrder, loading: loadingDeleteOrder }] = useMutation(DELETE_ORDER, {
    onError: (error) => {
      message.error(`Error while deleting cart: ${JSON.stringify(error?.message)}`)
      console.log(error)
    },
    onCompleted: (data) => {
      if (data.deleteOrder?.errors?.length === 0) message.success('Cart Deleted.')
    }
  })
  useGraphqlError(errorDeleteOrder)
  useErrors(dataDeleteOrder?.deleteOrder?.errors)

  const handleDeleteCart = () => {
    deleteOrder({ variables: { input: { id: customerCartId } } })
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <CustomerLink customer={customer}>View</CustomerLink>
      </Menu.Item>
      <Menu.Item key="1">
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          <DrawerLink drawerElement={CustomerEditDrawer} childProps={{ customer: customer }} clickable={canManageEntities} width={500}>
            {canManageEntities && <span>Edit Customer</span>}
            {!canManageEntities && (
              <Tooltip title="You must request permission to be able to manage entities.">
                <span style={{ color: 'gray' }}>Edit Customer</span>
              </Tooltip>
            )}
          </DrawerLink>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="2">
        <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
          {canManageEntities && (
            <span onClick={getSmsCode} style={{ cursor: 'pointer' }}>
              Get SMS Code
            </span>
          )}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Get SMS Code</span>
            </Tooltip>
          )}
        </Tooltip>
      </Menu.Item>
      {customerCartId && (
        <Menu.Item key="3">
          {canManageEntities && (
            <Popconfirm title={`Are you sure you want to clear this customer's cart`} onConfirm={handleDeleteCart} loading={loadingDeleteOrder}>
              <span>Clear Cart</span>
            </Popconfirm>
          )}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Clear Cart</span>
            </Tooltip>
          )}
        </Menu.Item>
      )}
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default CustomerActionsDropdown
