import React from 'react'
import ReactJson from 'react-json-view'
import { Tooltip } from 'antd'
import { LinkOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import humanizeString from 'humanize-string'
import truncate from 'truncate'
import moment from 'moment'
import { FormattedNumber } from 'react-intl'
import 'moment-timezone'
import Copy from '../components/shared/Copy'

export const boolFormatter = (value) => {
  return value ? 'Yes' : 'No'
}

export const dateFormatter = (cell, dateOnly, inUtc = false) => {
  if (cell == null) return <></>
  const est = moment(cell).tz('America/New_York')
  const utc = moment(cell).tz('utc')
  const format = dateOnly === true ? 'MMM D YYYY' : 'MMM D YYYY, h:mm A'
  const utcDateTime = utc.format(format)
  const inEst = est.format(format)
  const toolTipTitle = (
    <>
      <div>{`UTC: ${utcDateTime}`}</div>
      <div>{`RAW: ${moment(cell)}`}</div>
    </>
  )
  return (
    <Tooltip title={toolTipTitle}>
      <span className="nowrap">{inUtc ? utcDateTime : inEst}</span>{' '}
    </Tooltip>
  )
}

export const formatTimestamp = (value, dateOnly) => {
  const est = moment(value).tz('America/New_York')
  const format = dateOnly === true ? 'MMM D YYYY' : 'MMM D YYYY, h:mm A'
  const inEst = est.format(format)

  return inEst
}

export const linkFormatter = (cell, fallback) => {
  if (cell == null) return fallback || <></>
  return (
    <a href={cell} target="_blank" rel="noopener noreferrer">
      {cell}
    </a>
  )
}

export const jsonFormatter = (value, parse = true) => {
  let json = value
  if (!json) return ''
  if (parse) json = JSON.parse(json)
  return <ReactJson src={json} theme="rjv-default" collapsed={false} displayDataTypes={false} />
}

export const idFormatter = (id) => {
  return <span>{id.split('-')[0]}</span>
}

export const humanFormatter = (value) => {
  return value && humanizeString(value)
}

export const currencyFormatter = (value) => {
  if (value == null) return <span>None</span>
  // eslint-disable-next-line
  return <FormattedNumber style="currency" value={value / 100.0} currency={'USD'} />
}

export const checkFormatter = (isChecked) => {
  if (isChecked) return <CheckOutlined style={{ color: 'green' }} />

  return <CloseOutlined style={{ color: 'red' }} />
}

export const truncateFormatter = (text, maxLength) => {
  if (text == null) return <></>
  if (text.length <= maxLength) return text
  return <Tooltip title={text}>{truncate(text, maxLength || 20)}</Tooltip>
}

export const phoneFormatter = (phone) => {
  return (
    <Copy text={phone}>
      <a href={`tel:${phone}`}>{phone}</a>
    </Copy>
  )
}

export const addressFormatter = (address, recipient = true) => {
  if (address == null) return <></>
  let parts = [`${address.line1} ${address.line2 || ''}`, `${address.city}`, `${address.state} ${address.zip}`]
  if (recipient) parts.unshift(`${address.recipient || ''}`)
  return parts.join(', ')
}

export const emailFormatter = (email) => {
  return (
    <Copy text={email}>
      <a href={`mailto:${email}`}>{email}</a>
    </Copy>
  )
}
export const formattedError = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors.length === 0) {
    return networkError?.result?.error_localized || networkError?.result?.error
  }
  return graphQLErrors
    .map((graphqlError) => {
      return graphqlError.message
    })
    .join('\n')
}

export const studioHref = (path) => {
  return `${process.env.REACT_APP_STUDIO_URL}${path}`
}

/**
 * Provides a link to studio.photoday.io site
 * @param {*} path
 */
export const studioLinkFormatter = (path) => {
  return (
    <a href={studioHref(path)} target="_blank" rel="noopener noreferrer">
      <LinkOutlined />
    </a>
  )
}

export const HTMLError = ({ error }) => (
  <div>
    {error.graphQLErrors.map(({ message }) => (
      <p key="error-key">{message}</p>
    ))}
  </div>
)

export const mapCurrencyToCents = (object, fields) => {
  const newObject = fields.reduce((current, item) => {
    current[item] = object[item] * 100.0
    return current
  }, {})
  return { ...object, ...newObject }
}

export const mapCentsToCurrency = (object, fields) => {
  const newObject = fields.reduce((current, item) => {
    current[item] = (object[item] / 100.0).toFixed(2)
    return current
  }, {})
  return { ...object, ...newObject }
}

export const stripeTransferLink = (transferId) => {
  if (transferId == null || transferId === 'NONE') {
    return 'None'
  }
  const href = `https://dashboard.stripe.com/connect/transfers/${transferId}`
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {transferId}
    </a>
  )
}

export const jobListFormatter = (jobs) => {
  if (jobs.length > 1) {
    return `${jobs.length} Jobs`
  } else {
    const job = jobs[0].job
    return job ? job.name : ''
  }
}
