import React, { useCallback, useState } from 'react'
import { Menu, Dropdown, Tooltip, Modal, message } from 'antd'
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import DuplicatePriceSheetModal from './actions/DuplicatePriceSheetModal'
import DrawerLink from '../shared/DrawerLink'
import StudioLink from './StudioLink'
import StudioEditDrawer from './StudioEditDrawer'
import { hasPermission } from '../../utilities/permissions'
import { ARCHIVE_STUDIO } from '../../graphql/mutations'
import { useMutation } from '@apollo/react-hooks'

const StudioActionsDropdown = ({ studio }) => {
  const [showModal, setShowModal] = useState(false)

  const [archiveStudio] = useMutation(ARCHIVE_STUDIO, {
    update: (cache) => {
      cache.modify({
        fields: {
          studios(cachedData = [], { readField }) {
            return cachedData.items.filter((postRef) => studio.id !== readField('id', postRef))
          }
        }
      })
    }
  })

  const archive = useCallback(async () => {
    const params = { variables: { input: { id: studio.id } } }
    const response = await archiveStudio(params)
    const errors = response.data?.archiveStudio?.errors
    if (errors && errors.length > 0) return

    message.success('Studio successfully archived.')
  }, [studio, archiveStudio])

  const confirmArchiving = () => {
    Modal.confirm({
      title: 'Are you sure you want to archive this studio?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: archive
    })
  }

  const toggleShowModal = () => {
    setShowModal(!showModal)
  }

  const canManageEntities = hasPermission('admin_manage_entities')
  const canArchiveStudios = hasPermission('admin_archive_studios')

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <StudioLink studio={studio}>View</StudioLink>
      </Menu.Item>
      <Menu.Item key="1">
        <DrawerLink drawerElement={StudioEditDrawer} childProps={{ studio: studio }} clickable={canManageEntities} width={500}>
          {canManageEntities && <span>Edit Studio</span>}
          {!canManageEntities && (
            <Tooltip title="You must request permission to be able to manage entities.">
              <span style={{ color: 'gray' }}>Edit Studio</span>
            </Tooltip>
          )}
        </DrawerLink>
      </Menu.Item>
      <Menu.Item key="2">
        {canArchiveStudios && (
          <div className="link" onClick={confirmArchiving} style={{ cursor: 'pointer' }}>
            Archive
          </div>
        )}
        {!canArchiveStudios && (
          <Tooltip title="You must request permission to be able to archive studios.">
            <span style={{ color: 'gray' }}>Archive</span>
          </Tooltip>
        )}
      </Menu.Item>
      <Menu.Item key="3">
        {canManageEntities && (
          <div className="link" onClick={toggleShowModal} style={{ cursor: 'pointer' }}>
            Copy Price Sheet
          </div>
        )}
        {!canManageEntities && (
          <Tooltip title="You must request permission to be able to manage entities.">
            <span style={{ color: 'gray' }}>Copy Price Sheet</span>
          </Tooltip>
        )}
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
          Actions &nbsp;
          <DownOutlined />
        </span>
      </Dropdown>
      <DuplicatePriceSheetModal isVisible={showModal} onClose={toggleShowModal} studio={studio} />
    </>
  )
}

export default StudioActionsDropdown
