import React from 'react'
import { Menu, Dropdown, Tooltip } from 'antd'
import DrawerLink from '../shared/DrawerLink'
import { DownOutlined } from '@ant-design/icons'
import { hasPermission } from '../../utilities/permissions'
import PoliciesEditDrawer from './PoliciesEditDrawer'
import PolicyLink from '../policies/PolicyLink'

const PolicyActionsDropdown = ({ companyPolicy }) => {
  const canEdit = hasPermission('admin_update_company_policies') || hasPermission('super_admin')
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <PolicyLink companyPolicy={companyPolicy}>View</PolicyLink>
      </Menu.Item>
      <Menu.Item key="1">
        <DrawerLink drawerElement={PoliciesEditDrawer} childProps={{ companyPolicy: companyPolicy }} clickable={canEdit} width={500}>
          {canEdit && <span>Edit Company Policy</span>}
          {!canEdit && (
            <Tooltip title="You must request permission to be able to edit a company policy.">
              <span style={{ color: 'gray' }}>Edit Company Policy</span>
            </Tooltip>
          )}
        </DrawerLink>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default PolicyActionsDropdown
