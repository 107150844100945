import React from 'react'
import { Form, Button, message, Input } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'
import Drawer from '../shared/Drawer'
import { UPDATE_SUPPORT_REQUEST } from '../../graphql/mutations'
import PubSub from 'pubsub-js'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const { TextArea } = Input

const DenyDrawer = (props) => {
  const { request, onClose } = props
  const [updateSupportRequest, { data, loading, error }] = useMutation(UPDATE_SUPPORT_REQUEST)

  useErrors(data?.updateSupportRequest?.errors)
  useGraphqlError(error)

  const order = request.orderReprint?.order
  const isReprint = request.requestType === 'reprint'

  const onFinish = async (values) => {
    const params = {
      variables: {
        input: {
          id: request.id,
          status: 'denied',
          comment: values.comment,
          shippingRateId: request.orderReprint?.shippingRateId ? request.orderReprint?.shippingRateId : null
        }
      }
    }
    const response = await updateSupportRequest(params)
    const errors = response.data?.updateSupportRequest?.errors
    if (errors && errors.length > 0) return

    message.success('Request updated.')
    PubSub.publish('PAGED_LIST_REFRESH')
    onClose()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Drawer {...props} width={600}>
      {isReprint ? <h3>Deny request for order {order.num}</h3> : <h3>Deny request for custom promo {request.studioPromotion.promotion.name}</h3>}
      <Form {...layout} style={{ padding: 20 }} name="basic" initialValues={request} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="Reason" name={'comment'}>
          <TextArea rows={4} required={true}></TextArea>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Deny
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default DenyDrawer
