import React from 'react'
import { Menu, Dropdown, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import DrawerLink from '../shared/DrawerLink'
import RefundCreateDrawer from '../refunds/RefundCreateDrawer'
import CreditLink from './CreditLink'
import { hasPermission } from '../../utilities/permissions'

const CreditActionsDropdown = ({ credit }) => {
  const canManageRefunds = hasPermission('admin_manage_refunds')
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <CreditLink credit={credit}>View</CreditLink>
      </Menu.Item>
      <Menu.Item key="1" disabled={!credit.refundable}>
        <DrawerLink drawerElement={RefundCreateDrawer} childProps={{ credit: credit, payoutId: credit.payoutId }} clickable={canManageRefunds} width={500}>
          {canManageRefunds && <span>Create Refund</span>}
          {!canManageRefunds && (
            <Tooltip title="You must request permission to be able to manage refunds.">
              <span style={{ color: 'gray' }}>Create Refund</span>
            </Tooltip>
          )}
        </DrawerLink>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default CreditActionsDropdown
